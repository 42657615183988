import { getUserLocation } from "@jog/shared";
import { kebabCase } from "lodash-es";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
const DEFAULT_CITY = "Netherlands";
const LocationContext = createContext({
    city: DEFAULT_CITY,
    getLocation: () => Promise.resolve(DEFAULT_CITY),
});
export const LocationProvider = ({ children }) => {
    const [city, setCity] = useState("");
    const getLocation = useCallback(() => new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(async (position) => {
        const userLocation = await getUserLocation(position.coords);
        const city = (userLocation === null || userLocation === void 0 ? void 0 : userLocation.city) || DEFAULT_CITY;
        setCity(city);
        resolve(city);
    }, reject)), []);
    const value = useMemo(() => ({ city, getLocation }), [getLocation, city]);
    return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>;
};
export const getStoreLocator = (inputVal) => {
    const value = /^\d{4}$/.test(inputVal) ? `${inputVal},NL` : inputVal;
    return fetch(`${process.env.NEXT_PUBLIC_STORE_API}?q=${value}`)
        .then((response) => response.json())
        .then((res) => res.data.map((i) => ({ ...i, slug: `${kebabCase(i.town.toLowerCase())}-${i.storeNumber}` })))
        .catch((error) => {
        console.log(error);
        return [];
    });
};
export const useLocation = (init = true) => {
    const [pending, setPending] = useState(init);
    const [stores, setStores] = useState(null);
    const onSearch = useCallback(async (value) => {
        setPending(true);
        const data = await getStoreLocator(value.trim());
        setStores(data);
        setPending(false);
    }, []);
    const { city, getLocation } = useContext(LocationContext);
    useEffect(() => {
        if (city) {
            onSearch(city);
        }
    }, [city, onSearch]);
    useEffect(() => {
        if (init) {
            getLocation().catch(() => onSearch("Netherlands"));
        }
    }, [getLocation, init, onSearch]);
    return {
        pending,
        stores,
        onSearch,
        getLocation,
        city,
    };
};
export const useUserLocation = () => useContext(LocationContext);
