import { replacePlaceholderInUrl } from "@jog/shared";
import NextLink from "next/link";
export const Link = ({ children, href, to, external = false, css, ...other }) => {
    const sites = process.env.NEXT_PUBLIC_SITE_URL || "";
    // if (!to && !href) {
    //     logger.error("Link must have either a `to` or `href` prop.")
    //     return null
    // }
    if (to && !href) {
        href = to;
    }
    // Check if it is a relative link from the CMS
    if (href !== undefined && href !== null) {
        // Replace hard-coded domains also, as this makes testing impossible.
        href = replacePlaceholderInUrl(href.toString()).replace(sites, "");
    }
    const internal = /^\/(?!\/)/.test((href === null || href === void 0 ? void 0 : href.toString()) || "");
    // Use Link for internal links, and <a> for others
    if (internal && !external) {
        return (<NextLink prefetch={false} href={href || "/"} css={css} className="text-gray-dracula no-underline" {...other}>
                {children}
            </NextLink>);
    }
    else {
        return (
        // eslint-disable-next-line react/no-unknown-property
        <a className="text-gray-dracula no-underline" href={href === null || href === void 0 ? void 0 : href.toString()} css={css} {...other}>
                {children}
            </a>);
    }
};
